import React, { Fragment }  from "react"
import Header from "../../components/header"
import Footer from "../../components/Footer";
import TermsAndConditions from "../../components/TermsAndConditions";

const TermsAndConditionPage =()=>{
    return( 
        <Fragment>
            <Header/>
            <TermsAndConditions/>
            <Footer/>
        </Fragment>
    );
};
export default TermsAndConditionPage;