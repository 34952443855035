import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import signature from "../../img/signiture1.png";
import Img1 from "../../img/svg/Mask group2.webp";
import "./style.css";
const About = () => {
  const { t } = useTranslation();
  return (
    <section className="gauto-about-area section_70">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="about-left">
              <h2>{t("about_us_title")}</h2>
              <h3>Welcome to Viegas Car Rentals goa</h3>
              <p>
                Welcome to our Self-driven Car Rental service, a trailblazing
                way to explore the picturesque paradise of Goa at your own pace.
                Established in 2000, we have been revolutionizing the way
                travelers experience this coastal haven by providing seamless,
                convenient, and personalized transportation solutions.
              </p>
              <div className="about-list">
                <ul>
                  <li>
                    <span>
                      <FaCheck />
                    </span>
                    Hassle-free Booking
                  </li>
                  <li>
                    <span>
                      <FaCheck />
                    </span>
                    Transparent Pricing
                  </li>
                  <li>
                    <span>
                      <FaCheck />
                    </span>
                    Diverse Range of Cars
                  </li>
                  <li>
                    <span>
                      <FaCheck />
                    </span>
                    Regularly Serviced Cars
                  </li>
                </ul>
              </div>
              <div className="about-signature">
                <div className="signature-left">
                  <img src={signature} alt="signature" />
                </div>
                <div className="signature-right">
                  <h3>Lengyel Viegas</h3>
                  <p>{t("Co-Founder")}</p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="about-right">
              <img  src={Img1} alt="car" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default About;
