import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../../img/Hassle-free Booking.png";
import img2 from "../../img/Diverse Range of Cars.png";
import img3 from "../../img/Transparent-Pricing.png";
import img4 from "../../img/Secure Payment.png";
import img5 from "../../img/Regularly Serviced Cars.png";
import img6 from "../../img/24x7 Customer Support.png";

import "./style.css";

const Service = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    arrows: false,
    speed: 1200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="gauto-service-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              {/* <h4>{t("see_our")}</h4> */}
              {/* <h2>{t("latest_service")}</h2> */}
              <h2>What Sets Us Apart </h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Slider className="service-slider" {...settings}>
              <div className="slide">
                <Col>
                <Row>
                <div className="single-service">
                  <span className="service-number">01 </span>
                  <div className="service-icon">
                    <img src={img1} alt="city trasport" />
                  </div>
                  <div className="service-text">
                    <Link to="">
                   
                      <h3>Hassle-free Booking</h3>
                    </Link>
                    <p >
                      Experience the ultimate ease with our hassle-free car
                      booking process, ensuring your journey from selection to
                      reservation is seamless and stress- free
                    </p>
                  </div>
                </div>
                
                </Row>
                
                </Col>
               
               
              </div>
              <div className="slide">
                <div className="single-service">
                  <span className="service-number">02 </span>
                  <div className="service-icon">
                    <img src={img2} alt="airport trasport" />
                  </div>
                  <div className="service-text">
           
                      {/* <h3>{t("air_transfer")}</h3> */}
                      < h3>Diverse Range of Cars</h3>
                   
                    <p >
                      Our extensive vehicle selection is tailored to your
                      requirements, featuring compact cars for urban mobility
                      and spacious SUVs perfect for family journeys
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-service">
                  <span className="service-number">03 </span>
                  <div className="service-icon">
                    <img src={img3} alt="hospital trasport" />
                  </div>
                  <div className="service-text">
                    <Link to="">
                      {/* <h3>{t("hospital_transfer")}</h3> */}
                      <h3 >Transparent Pricing</h3>
                    </Link>
                    <p >
                      Discover peace of mind with our transparent pricing ; no
                      hidden fees, just exceptional value
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-service">
                  <span className="service-number">04 </span>
                  <div className="service-icon">
                    <img src={img4} alt="wedding trasport" />
                  </div>
                  <div className="service-text">
                    <Link to="">
                      {/* <h3>{t("wedding_ceremony")}</h3> */}
                      <h3 >Secure Payment</h3>
                    </Link>
                    <p >
                      Prioritizing your security, we offer a sophisticated
                      payment system, ensuring your transactions are conducted
                      with the utmost safety and peace of mind
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-service">
                  <span className="service-number">05 </span>
                  <div className="service-icon">
                    <img src={img5} alt="wedding trasport" />
                  </div>
                  <div className="service-text">
                    <Link to="">
                      {/* <h3>{t("city_tour")}</h3> */}
                      <h3 >Regularly Serviced Cars</h3>
                    </Link>
                    <p >
                      Enjoy peace of mind as you drive our cars, not only
                      meticulously maintained for consistent performance but
                      also sanitized after every use for your health and safety.
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-service">
                  <span className="service-number">06 </span>
                  <div className="service-icon">
                    <img src={img6} alt="wedding trasport" />
                  </div>
                  <div className="service-text">
                    <Link to="">
                      {/* <h3> {t("baggage_transport")}</h3> */}
                      <h3> 24x7 Customer Support</h3>
                    </Link>
                    <p style={{textAlign:"center",}}>
                      Experience uninterrupted assistance with our dedicated
                      24/7 customer support; swiftly addressing inquiries,
                      offering solutions, and ensuring your seamless journey
                      with us.
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Service;
