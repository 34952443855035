import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaHeart,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaSkype,
  FaPaperPlane,
  FaInstagram,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import logo from "../../img/footer-logo.png";
import img1 from "../../img/call icon.svg";
import img2 from "../../img/message icon.svg";
import img3 from "../../img/Location Icon.svg";
import img4 from "../../img/Vector.svg";

import "./style.css";

const Footer = () => {
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <footer className="gauto-footer-area">
      <div className="footer-top-area">
        <Container>
          <Row>
            <Col lg={3}>
              <div className="single-footer">
                <div className="footer-logo">
                  <Link to="/">
                    <img src={logo} alt="footer-logo" />
                  </Link>
                </div>
                <p style={{ textAlign: "justify", lineHeight: "1.3em" }}>
                  Planning a trip to Goa? Add to your tour excitement with a
                  hassle free visit in the state. Make 'Viegas car rentals' your
                  trusted car rental partner in town! . We offer a range of cars
                  that suit your personality at affordable rates. our packages
                  range from a day to a month, Hence taking care of your travel
                  needs throughout your stay!
                </p>

                <div className="footer-address">
                  {/* <Link to="/" onClick={onClick} className="gauto-btn">
                    {t("researve_now")}
                    Get Quote
                  </Link> */}
                  {/* <h3>{t("footer.head_office")}</h3>
                  <p>
                    125 Big fella St. Road, <span>New York, Hi 5654775</span>
                  </p>
                  <ul>
                    <li>{t("footer.phone")}: 326487652 </li>
                    <li>{t("footer.email")}: example@mail.com</li>
                    <li>{t("footer.office_time")}: 9AM- 4PM</li>
                  </ul> */}
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="single-footer quick_links">
                <h3>{t("footer.quick_links")}</h3>
                <ul className="quick-links">
                  <li>
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li>
                    <Link to="/about"> {t("footer.about_us")}</Link>
                  </li>
                  <li>
                    <Link to="/car-listing">{t("Our Cars")}</Link>
                  </li>
                  {/* <li>
                    <Link to="" onClick={onClick}>
                      {t("FAQ")}
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/contact">{t("footer.contact_us")}</Link>
                  </li>
                </ul>
                {/* <ul className="quick-links">
                  <li>
                    <Link to="/" onClick={onClick}>
                      {t("footer.testimonials")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={onClick}>
                      {t("footer.privacy")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={onClick}>
                      {t("footer.latest_news")}
                    </Link>
                  </li>
                </ul> */}
              </div>
              {/* <div className="single-footer newsletter_box">
                <h3>{t("footer.newsletter")}</h3>
                <form onSubmit={SubmitHandler}>
                  <input type="email" placeholder="Email Address" />
                  <button type="submit">
                    <FaPaperPlane />
                  </button>
                </form>
              </div> */}
            </Col>
            <Col lg={3}>
              <div className="single-footer quick_links">
                <h3>{t("site links")}</h3>
                <ul className="quick-links">
                  {/* <li>
                    <Link to="/testimonials" onClick={onClick}>
                      {" "}
                      {t("Testimonials")}
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/testimonials"> {t("Testimonials")}</Link>
                  </li>
                  {/* <li>
                    <Link to="" onClick={onClick}>
                      {" "}
                      {t("Privacy Policy")}
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="" onClick={onClick}>
                      {t("Latest News")}
                    </Link>
                  </li>
                  <li>
                    <Link to="" onClick={onClick}>
                      {t("Videos")}
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="" onClick={onClick}>
                      {t("Privacy Policy")}
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/termsandcoditions">
                      {t("Terms And Conditions")}
                    </Link>
                  </li>
                </ul>
                {/* <ul className="quick-links">
                  <li>
                    <Link to="/" onClick={onClick}>
                      {t("footer.testimonials")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={onClick}>
                      {t("footer.privacy")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={onClick}>
                      {t("footer.latest_news")}
                    </Link>
                  </li>
                </ul> */}
              </div>
              {/* <div className="single-footer newsletter_box">
                <h3>{t("footer.newsletter")}</h3>
                <form onSubmit={SubmitHandler}>
                  <input type="email" placeholder="Email Address" />
                  <button type="submit">
                    <FaPaperPlane />
                  </button>
                </form>
              </div> */}
            </Col>

            <Col lg={3}>
              <div className="single-footer get-in-touch">
                <h3>{t("GET IN TOUCH")}</h3>
                <ul>
                  <li style={{ marginBottom: "5px" }}>
                    <Row>
                      <Col md="2">
                        <div className="footer-post-image">
                          <Link to="">
                            <img src={img1} alt="footer post" />
                          </Link>
                        </div>
                      </Col>
                      <Col md="10">
                        <Row>: +91 98501 14297</Row>
                        <Row>&nbsp; +91 88055 24237</Row>
                      </Col>
                    </Row>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <Row>
                      <Col md="2">
                        <div className="footer-post-image">
                          <Link to="">
                            <img src={img2} alt="footer post" />
                          </Link>
                        </div>
                      </Col>
                      <Col md="10">
                        <Row>:&nbsp;viegascarrentals.info@gmail.com</Row>
                      </Col>
                    </Row>
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    <Row>
                      <Col md="2">
                        <div className="footer-post-image">
                          <Link to="">
                            <img src={img3} alt="footer post" />
                          </Link>
                        </div>
                      </Col>
                      <Col md="10">
                        <Row>
                          : Opposite new Bank Of &nbsp;&nbsp;India,Possrem
                          Bhat,Taleigao,{" "}
                        </Row>
                        <Row>&nbsp;&nbsp;Panjim Goa- 403002, India</Row>
                      </Col>
                    </Row>
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    <Row>
                      <Col md="2">
                        <div className="footer-post-image">
                          <Link to="">
                            <img src={img4} alt="footer post" />
                          </Link>
                        </div>
                      </Col>
                      <Col md="10">
                        <Row>
                          : Office Timings 9:00 AM - &nbsp;&nbsp;11:00PM{" "}
                        </Row>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "20px" }} className="single-footer">
                <h3> {t("FOLLOW US")}</h3>
                <div className="footer-social">
                  <ul
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <li>
                      <a
                        href="https://www.facebook.com/viegascarrentalsgoa"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick}>
                        {/* <FontAwesomeIcon icon="fa-brands fa-x-twitter" /> */}
                        <FaXTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick}>
                        <FaLinkedinIn />
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/viegascarrentalsgoa?igshid=MzRlODBiNWFlZA%3D%3D"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom-area">
        <Container>
          <Row>
            <Col md={12}>
              <div className="copyright">
                <p>
                  © 2023 Viegas Car Rentals | All Rights Reserved. <br />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Designed With <FaHeart /> By &nbsp;
                    <a
                      href="https://www.novoup.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      NovoUp
                    </a>
                  </div>
                </p>
              </div>
            </Col>
            <Col md={6}>
              {/* <div className="footer-social">
                <ul>
                  <li>
                  <a href="https://www.facebook.com/viegascarrentalsgoa" target="_blank" rel="noopener noreferrer" >
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <Link to="/" onClick={onClick}>
                      <FaTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={onClick}>
                      <FaLinkedinIn />
                    </Link>
                  </li>
                  <li>
                  <a href="https://www.instagram.com/viegascarrentalsgoa?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank" rel="noopener noreferrer">

                    <FaInstagram />
                    </a>
                  </li>
                </ul>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
