import React from "react";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import hero1 from "../../img/slider-1.jpg";
import hero2 from "../../img/slider-2.jpg";
import fortuner from "../../img/svg/fortuner.svg";

import "./style.css";

const Hero = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClick = (e) => {
    navigate("/car-listing");
    e.preventDefault();
  };

  const settings = {
    dots: false,
    arrows: false,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
  };

  return (
    <section className="gauto-slider-area fix">
      <Slider {...settings}>
        {/* <div className="">
          <div
            className=" gauto-main-slide"
            style={{ backgroundImage: `url(${hero1})` }}
          >
            <div className="gauto-main-caption">
              <div className="gauto-caption-cell">
                <Container>
                  <Row>
                    <Col md={8}  >
                      <div className="slider-text">
                     
                          <div>     <span >Goa's Most Loved & Trusted <br/>SELF-DRIVE CAR RENTAL
                          SERVICE!</span></div>
                     
                        <Link to="/car-listing" onClick={onClick} style={{zIndex:1}} className="gauto-btn">
                          {t("Pick Your Wheels")}
                        </Link>
                      </div>
                    </Col>
                   
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div> */}
        <div className="">
          <div
            className="gauto-main-slide"
            style={{
              backgroundImage: `url(${hero2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="gauto-main-caption">
              <div className="gauto-caption-cell">
                <Container>
                  <Row>
                    <Col md={8}>
                      <div className="slider-text">
                        <h1>
                          Goa’s Best & Most Trusted Self-drive Car Rental
                          Service
                        </h1>
                      </div>
                      <Link
                        to="/car-listing"
                        onClick={onClick}
                        className="gauto-btn"
                      >
                        {t("Pick Your Wheels")}
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default Hero;
