import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaQuoteRight } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../../img/testimonial.jpg";
import img2 from "../../img/testimonial-2.jpg";
import img3 from "../../img/testimonial-3.jpg";
import KRITIKAMANE from "../../img/testimonials/KRITIKA-MANE.png";
import SUJITJANARDANAN from "../../img/testimonials/SUJIT-JANARDANAN.png";
import SHREYNAYGANDHI from "../../img/testimonials/SHREY-NAYGANDHI.png";
import NeelMani from "../../img/testimonials/Neel-Mani.png";
import DHARAMPESWANI from "../../img/testimonials/DHARAM-PESWANI.png";
import AYUSHSHARMA from "../../img/testimonials/AYUSH-SHARMA.png";

import "./style.css";

const Testimonial = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    arrows: false,
    speed: 1200,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="gauto-testimonial-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              {/* <h4>{t("some_words")}</h4> */}
              <h2>{t("testimonial")}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Slider className="testimonial-slider" {...settings}>


              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "It was a great trip with your car by our side. The best
                      deal I would have ever got and with great discounts. The
                      car was superb in condition and maintained really well.
                      You guys are really friendly with right coordination."
                    </p>
                    <div className="testimonial-meta">
                      <div className="client-image">
                        <img src={KRITIKAMANE} alt="testimonial" />
                      </div>
                      <div className="client-info">
                        <h3>KRITIKA MANE</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "It was a breeze booking our self-drive cars via Lengyel
                      and team. Loved the smooth experience right from drop to
                      our hotel to the pickup at the airport. Highly recommended
                      for vehicle rental services in this part of the world!"
                    </p>
                    <div  className="testimonial-meta">
                      <div className="client-image">
                        <img src={SUJITJANARDANAN} alt="testimonial" />
                      </div>
                      <div className="client-info">
                        <h3>SUJIT JANARDANAN</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p >
                      "Amazing experience! All the cars were brand new. Pickup
                      and drop experience was also smooth. They are very
                      professional and helpful in every way possible!"
                    </p>
                    <div className="testimonial-meta">
                      <div className="client-image">
                        <img src={SHREYNAYGANDHI} alt="testimonial" />
                      </div>
                      <div className="client-info">
                        <h3>SHREY NAYGANDHI</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "Undoubtedly one of the best car rental services in Goa!
                      We got an airport pick and drop, clean car and on time
                      delivery. Hassle-free process and at a reasonable price,
                      this was all I need. Thanks Viegas for your support. Will
                      definitely recommend to my friends."
                    </p>
                    <div className="testimonial-meta">
                      <div className="client-image">
                        <img src={AYUSHSHARMA} alt="testimonial" />
                      </div>
                      <div className="client-info">
                        <h3>AYUSH SHARMA</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "They are the best car rental service providers in Goa!
                      The cars are well maintained and are available at
                      reasonable rates. Lengyel has been cooperative and a call
                      away at all times."
                    </p>
                    <div className="testimonial-meta">
                      <div className="client-image">
                        <img src={NeelMani} alt="testimonial" />
                      </div>
                      <div className="client-info">
                        <h3>NEEL MANI</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "Had a great experience with Viegas Car Rentals. Very
                      professional and well spoken guys. Would defineltly
                      recommend Viegas Car rental to all those coming to Goa for
                      a good time. Their drop service to and from the airport
                      was just very comfortable. Special thanks to Vipul for
                      doing it so smoothly"
                    </p>
                    <div className="testimonial-meta">
                      <div className="client-image">
                        <img src={DHARAMPESWANI} alt="testimonial" />
                      </div>
                      <div className="client-info">
                        <h3>DHARAM PESWANI</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonial;
